import { AccordionDetails, Typography } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { ExpandMore } from '@material-ui/icons';
import { createUseStyles } from 'react-jss';
import ReactMarkdown from 'react-markdown';

const useStyles = createUseStyles({
  container: {
    padding: '0 10px'
  },
  answers: {
    flexDirection: 'column'
  },
  '@media screen and (max-width:760px)': {
    container: {}
  }
});

interface IFAQ {
  question: string;
  answer: string;
}

interface IFaqs {
  title: string;
  faq_list?: Array<IFAQ>;
}

function FAQContainer({ faqs }: { faqs: IFaqs }) {
  const classes = useStyles();

  if (faqs.faq_list == undefined || faqs.faq_list.length == 0) return null;

  return (
    <div className="max-width">
      <div className="ptb50">
        <div className={classes.container}>
          <Typography variant="h1" className="center mb50">
            {faqs.title || 'FAQs'}
          </Typography>
          {faqs.faq_list?.map((item, index) => (
            <Accordion key={index}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant="h5">{item.question}</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.answers}>
                <ReactMarkdown source={item.answer} />
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FAQContainer;
